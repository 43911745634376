@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;1,400;1,600&family=Poppins:ital,wght@0,600;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Nunito+Sans:ital,wght@0,300;1,400;1,600&family=Poppins:ital,wght@0,600;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }

  body {
    scroll-behavior: smooth;
  }
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vh;
  height: 60vh;
  z-index: 100;
  background-color: rgb(41, 41, 41);
  margin: 0 auto;
  z-index: 1;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s ease-in-out;
  font-family: "Golos Text", sans-serif;
}
.modal .buttonX .icon {
  font-size: 25px;
  padding: 10px;
  color: orangered;
}
.modal .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.modal .modal-content .video {
  margin-top: 15px;
  border: none;
  width: 100%;
  height: 100%;
}

.modal .modal-content .title {
  font-size: 25px;
  font-weight: 500;
  font-family: "Golos Text", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}

@media screen and (max-width: 768px) {
  .modal {
    /* width: 100vh; */
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
  }
  .modal .modal-content .video {
    height: 40vh;
  }
}
