.frontSpan {
  color: red;
  animation: change-color 7s ease-in 0s infinite normal;
}

@keyframes change-color {
  0% {
    color: green;
  }
  20% {
    color: blue;
  }
  40% {
    color: dodgerblue;
  }
  60% {
    color: green;
  }
  80% {
    color: blue;
  }
  100% {
    color: red;
  }
}

.explore {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
  animation: jump 2s ease-out 0s infinite normal;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(15px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.image {
  animation: image 1.5s ease-in 0s;
}

@keyframes image {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
